import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const Select = styled.select`
  position: relative;
  border-radius: 10px;
  width: 100%;
  border: none;
  margin: 0 0 0.75rem;
  color: #707070;
  font-size: 0.875rem;
  outline: none;
  background-color: #fcf8ef;
  border: none;
  padding-left: 25px;
  box-sizing: border-box;
  overflow: visible;
  min-height: 3.125rem;
  &::placeholder {
    font-style: italic;
    color: rgba(130, 130, 130, 1);
  }
  &.input-error {
    box-shadow: 0 0 0 1px var(--accent);
  }
  & + .error-icon {
    text-align: center;
    display: block;
    font-weight: 700;
    color: var(--secondary-text);
    background-color: var(--accent);
    min-height: 1.375rem;
    min-width: 1.375rem;
    border-radius: 50%;
    padding-top: 0.125rem;
    margin-right: 0.2rem;
    position: absolute;
    @media (max-width: 48em) {
      margin-left: clamp(13rem, 70.54vw, 22.3rem);
    }
  }
`;
