import styled from "styled-components";

export const Button = styled.a`
    line-height: normal !important;
    margin-top: -12px;
    button {
        margin-right: 0;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 28px;
        padding-right: 28px;
    }
`

export const Footer = styled.div`
    background-color: var(--primary);
    padding-top: 11.25rem;
    text-align: center;
    .social {
        img {
            margin: 0 0.3rem;
        }
    }
    @media (max-width: 768px){
        padding-top: 3.125rem;
    }
    div.col-md-3 > img {
        transform: scale(1.4);
        margin-bottom: 1rem;
    }
    p{
        font-family: 'ubuntu';
        font-weight: 300;
        color: var(--secondary-text);
        font-size: 15px;
        line-height: 17px;
        @media (max-width: 768px){
            margin-bottom: 5px;
            margin-top: 1.25rem;
        }
    }
    a{
        font-family: 'ubuntu';
        font-weight: bold;
        color: var(--secondary-text);
        font-size: 34px;
        line-height: 39px;
        text-decoration: none;
    }
    .social{
        padding-top: 30px;
        img{
            margin: 0px 10px;
        }
    }
    .txt-footer{
        border-bottom: solid 1px var(--secondary-text);
        margin-top: 30px;
        padding: 15px 0;
        p{
            text-align: justify;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 100;
            color: var(--secondary-text);
            font-size: 0.813rem;
            line-height: 20px;
        }
        @media (max-width: 768px){
            padding: 15px;
            width: 95%;
            margin: 0 auto;
            p{
                text-align: center;
            }
        }
    }
    .credits{
        display: flex;
        justify-content: center;
        padding: 25px 0;
        position: relative;
        @media (max-width: 768px){
            p{
                margin-top: 0;
            }
        }
        button{
            max-width: 200px;
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 14px;
            border-radius: 30px;
            position: relative;
            bottom: 6px;
            margin-left: 10px;
        }
        img{
            position: absolute;
            right: 20px;
        }
    }
`;